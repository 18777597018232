<div class="wrapper page-header">
    <div class="signin">
        <div class="filter"></div>
        <div class="container">
            <div class="row">
                <div class="mr-auto ml-auto">
                    <div class="card card-register">
                        <h1 class="title">Verify Your {{mode}}</h1>
                        <span class="underline-text-light"></span>
                        <form class="register-form mt-5 mx-auto" [formGroup]="verifyForm" (ngSubmit)="verify()">
                            <div class="email" *ngIf="mode === 'Email' ">
                                <label>Email</label>
                                <div class="input-group form-group-no-border">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="nc-icon nc-email-85"></i>
                                        </span>
                                    </div>
                                    <input type="email" class="form-control" placeholder="Email" formControlName="email"
                                        required>

                                </div>
                                <div *ngIf="email && !email.valid && (verifyForm.dirty || verifyForm.touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="email.errors['required']">
                                        Email is required.
                                    </div>
                                    <div *ngIf="email.errors['email']">
                                        Please enter valid email.
                                    </div>
                                </div>
                            </div>

                            <div class="phone_number" *ngIf="mode === 'Phone' ">
                                <div id="captcha-container"></div>
                                <div class="form-group mb-0">
                                    <label>Phone</label>
                                    <div class="input-group form-group-no-border ">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                +1
                                            </span>
                                        </div>
                                        <input class="form-control pl-0" appPhoneMask
                                            formControlName="phone_number" required>
                                    </div>
                                </div>
                                <div *ngIf="phone_number && !phone_number.valid && (verifyForm.dirty || verifyForm.touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="phone_number.errors">
                                        Please enter valid phone_number number.
                                    </div>
                                </div>

                            
                                <div *ngIf="oobCodeConfirmation">
                                    <div class="form-group mb-0">
                                        <label>Verification Code</label>
                                        <div class="input-group form-group-no-border ">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="nc-icon nc-key-25"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" formControlName="otp" required>
                                        </div>
                                    </div>
                                    <div *ngIf="otp && !otp.valid && (otp.dirty || otp.touched)"
                                        class="alert alert-danger">
                                        <div *ngIf="otp.errors">
                                            Please enter valid code sent to your mobile.
                                        </div>
                                    </div>
                                    <div class="forgot">
                                        <h4 class="">Didn't received a verification code?</h4>
                                        <a (click)="sendVerificationCodeOnMobile()" class="btn btn-dark btn-link text-white mt-n2">
                                            <u>Resend Verification Code</u>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row justify-content-center">
                                <button [disabled]="!verifyForm.valid || loading" type="submit"
                                    class="btn btn-danger">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="loading"></span>
                                    {{mode === "Phone" && !oobCodeConfirmation ? "Send Verification Code" : "It's ME!"}}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
