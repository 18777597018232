import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UikitFormComponent } from './uikit-form.component';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { UikitErrorComponent } from 'src/app/components/uikit-error/uikit-error.component';
import { JwBootstrapSwitchNg2Module } from '@servoy/jw-bootstrap-switch-ng2';
import { ScheduledAtComponent } from './input-types/scheduled-at/scheduled-at.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ChipsModule} from 'primeng/chips';
import { AngularMultiSelectModule } from 'ngb-dropdown';
import { QEditorComponent } from 'src/app/components/q-editor/q-editor.component';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ButtonModule } from 'primeng/button';
import { PhoneMaskDirective } from '../../directives/phone-mask.directive';

@NgModule({
  declarations: [UikitFormComponent, ScheduledAtComponent],
  imports: [
    CommonModule,
    ChipsModule,
    FormsModule,
    QEditorComponent,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    UikitErrorComponent,
    AngularMultiSelectModule, 
    JwBootstrapSwitchNg2Module,
    TriStateCheckboxModule,
    ButtonModule,
    PhoneMaskDirective
  ],
  exports: [UikitFormComponent]
})
export class UikitFormModule { }
