import { Component } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { Params } from '@angular/router';
import { LocalStorageService, STORAGE_CONSTANTS } from './shared/services/storage.service';
import { ToastService } from './shared/services/toast.service';
import { lastValueFrom } from 'rxjs';
import { Logger } from './shared/utils/log-util';
import { AppService } from './shared/services/app.service';

@Component({
    selector: 'qatch-app',
    templateUrl: './qatch-app.component.html'
})
export class QatchAppComponent {

    isReady: boolean;
    signInProgress: boolean;
    currentRoute: string;
    
    constructor(
        private authService: AuthService,
        private toastService: ToastService,
        private storageService: LocalStorageService,
        private appService: AppService
    ) {
        this.getAuthState();
        const urlParams = new URLSearchParams(window.location.search);
        this.currentRoute = this.getCurrentRouteWithoutMagictoken(urlParams);
        const magiclinkToken = urlParams.get('magic-token');
        if(magiclinkToken && !this.signInProgress) {
            this.loginByMagiclinkToken(magiclinkToken, urlParams);
        }
    }

    async getAuthState() {
        // Add listener for listening to Firebase auth state events
        await this.authService.writeAuthToken();
        this.authService.authState().subscribe(async (result) => {
            await this.authService.writeAuthToken();
            if (result) {
                // Set auto mode on for refreshing Auth token
                this.authService.setAutoRefreshAuthToken();
                this.authService.me(true).then(() => {
                }).finally(()=>{
                    this.markAuthReady(false);
                });
            } else {
                this.markAuthReady(false);
            }
        });
    }

    async loginByMagiclinkToken(magicToken: string, params: Params) {
        this.signInProgress = true;
        lastValueFrom(this.authService.createCustomToken({magicToken}))
        .then(async (resp) => {
          await this.authService.logOut();
          this.authService.signInWithCustomToken(resp).then(() => {
            this.storageService.set(STORAGE_CONSTANTS.REDIRECT_TO_URL_ON_SIGNIN, this.currentRoute);
            this.storageService.storeInAppCache(STORAGE_CONSTANTS.JUST_LOGGED_IN_EVENT, 'true');
            this.markAuthReady(true);
          }).catch((error) => {
            this.handleMagicLinkAuthFailure(error);
          });
        })
        .catch((error) => {
            this.handleMagicLinkAuthFailure(error);
        });
      }

      handleMagicLinkAuthFailure(error: any){
        this.markAuthReady(true);
        Logger.debugError(error);
        if(this.authService.isLoggedIn()){
          this.appService.navigateWithPreserve(`/home`);
        }else{
          this.appService.navigateWithPreserve(`/signin`);
        }
        setTimeout(() => {
          this.toastService.error(error);
        });
      }
    
      getCurrentRouteWithoutMagictoken(queryParams: any) {
        let route = location.href.replace(location.origin, '')
        route = route.slice(0, route.indexOf('?'));
        const filteredParams = [];
        queryParams.forEach((value, key) => {
            if(key !== 'magic-token'){
                filteredParams.push(`${key}=${value}`);
              }
        });
        return `${route}?${filteredParams.join('&')}`;
      }

      markAuthReady(resetSignProgress: boolean){
        if(resetSignProgress){
            this.signInProgress = false;
            this.isReady = true;
        }else if(this.signInProgress){
            this.isReady = false;
        }else{
            this.isReady = true;
        }
      }
}
