import _ from 'lodash';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UikitFormModule } from 'src/app/shared/modules/uikit-form/uikit-form.module';
import { RouterModule } from '@angular/router';
import { FormFieldTypes, IFormField, UikitFormComponent } from 'src/app/shared/modules/uikit-form/uikit-form.component';
import { SigninComponent } from '../signin/signin.component';
import { Validators } from '@angular/forms';
import { QButtonComponent } from 'src/app/components/q-button/q-button.component';
import { QuickSignUpComponent } from '../quick-sign-up/quick-sign-up.component';

const QUICKSIGNUP_FORM_FIELDS: IFormField[] = [
    {
      label: '',
      type: FormFieldTypes.phone,
      fieldId: 'username',
      placeholder:'phone number',
      validators: [Validators.required, Validators.pattern('^[0-9]{10}$')] 
    }
];

@Component({
    standalone: true,
    selector: 'app-quick-sign-in',
    templateUrl: './quick-sign-in.component.html',
    styleUrls: ['./quick-sign-in.component.scss'],
    imports: [CommonModule, UikitFormModule, QButtonComponent, RouterModule],
})
export class QuickSignInComponent extends SigninComponent implements AfterViewInit {
    formFields = _.cloneDeep(QUICKSIGNUP_FORM_FIELDS);
    @ViewChild(UikitFormComponent) uikitFormComponent: UikitFormComponent;

    override ngOnInit() {}

    ngAfterViewInit(): void {
        this.authForm = this.uikitFormComponent.form;
    }

    onClose() {
        this.activeModal.dismiss();
    }

    openSignupPopup() {
        this.onClose();
        this.ngbModal.open(QuickSignUpComponent, {
            centered: true, size: 'md', windowClass: 'quick-popup'
        });
    }

}
