<nav class="navbar navbar-expand-lg fixed-top" color-on-scroll="500"
    [ngClass]="{'navbar-transparent': hasTransparentNavbar()}" [class.header-invisible]="headerInVisible">
    <div class="q-navbar-container" [class.container-fluid]="!sidebarVisible">
        <div class="navbar-translate header-title-logo" [class.d-none]="sidebarVisible">
            <div class="d-flex h-100">
                <ng-container *ngTemplateOutlet="menuToggle"></ng-container>
                <a class="navbar-brand qatch_logo d-flex p-0" [routerLink]="['/home']" *ngIf="!headerTitle || isDesktop">
                    <img class="my-auto" src="./assets/img/qatch-logo{{logoSuffix}}.png" [class.beta]="isLoggedIn" />
                </a>
                <strong *ngIf="!isDesktop && headerTitle" class="card-title q-page-header-title my-auto">{{ headerTitle }}</strong>
            </div>
            <div class="d-flex" *ngIf="!isDesktop">
                <a *ngIf="isVisible()" class="dropdown-item home_sign_up position-relative p-2 glow-on-btn" [routerLink]="'/signup'">
                    <span>{{discoverPage ? 'FREE STYLING' : 'JOIN NOW'}}</span>
                </a>
                <a *ngIf="isLoggedIn" class="dropdown-item position-relative p-2 mobile-header-heart-icon d-flex" style="font-size: 20px;" 
                   [routerLink]="'/internal/my-qatch'">
                    <i class="nc-icon bi bi-heart-fill my-auto"></i>
                </a>
                <a *ngIf="isLoggedIn" class="dropdown-item position-relative p-2 mobile-header-person-icon d-flex" style="font-size: 28px;" 
                   [routerLink]="'/general/edit-account'">
                    <i class="nc-icon bi bi-person mb-1"></i>
                </a>
            </div>
        </div>
        <div *ngIf="sidebarVisible" class="side-bar-backdrop" data-toggle="collapse" data-target="#navbarToggler"
            aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation"
            (click)="sidebarToggle()">
            <ng-container *ngTemplateOutlet="menuToggle"></ng-container>
        </div>
        <div class="collapse navbar-collapse justify-content-end q-side-menu" id="navbarToggler">

            <ul class="navbar-nav q-menu-item-list {{rolesList}}" *ngIf="!loading && menuItems && menuItems.length>1">
                <li *ngIf="!isDesktop && isLoggedIn" class="nav-item py-3 q-user-profile d-flex">
                    <a class="menu-brand">
                        <img class="img-circle"
                            [src]="user['profile-img'] ? user['profile-img'] : './assets/img/default_profile.jpg'" />
                    </a>
                    <div class="pl-2">
                        <strong> {{user.first_name}} {{user.last_name ? user.last_name : ''}} </strong>
                        <p> {{user.email ? user.email : ''}} </p>
                    </div>
                </li>
                <ng-container *ngFor="let menuItem of menuItems; index as indx;">
                    <li class="nav-item position-relative" [ngClass]="{'pt-2 pb-2':!isDesktop}">
                        <a class="nav-link {{menuItem.classes}}" *ngIf="!menuItem.subItems || !menuItem.subItems.length"
                            [class.p-1]="!isDesktop" [innerText]="menuItem.label"
                            [routerLink]="menuItem.route ? menuItem.route : []"
                            [routerLinkActive]="menuItem.route ? 'active-menu-item' : ''"
                            (click)="onItemClick(menuItem)"></a>
                        <span *ngIf="menuItem.badge || menuItem.badge?.label>0"
                            class="badge {{menuItem.badge?.classes}} position-absolute">{{menuItem.badge?.label}}</span>

                        <div ngbDropdown class="dropdown" placement="bottom-left"
                            *ngIf="menuItem.subItems && menuItem.subItems.length"
                            [routerLinkActive]="'active-dropdown'">
                            <a *ngIf="menuItem.route; else withoutRoute"
                                class="nav-link {{hasActiveMenu(menuItem.subItems)}} {{ (menuItem.id && menuItem.id === 'profile') ? 'd-lg-none' : ''}}"
                                ngbDropdownToggle [class.p-1]="!isDesktop" [innerText]="menuItem.label"
                                [routerLink]="menuItem.route ? menuItem.route : []"
                                [routerLinkActive]="menuItem.route ? 'active-menu-item' : ''">
                            </a>
                            <ng-template #withoutRoute>
                                <a class="nav-link {{hasActiveMenu(menuItem.subItems)}} {{ (menuItem.id && menuItem.id === 'profile') ? 'd-lg-none' : ''}}"
                                    ngbDropdownToggle [class.p-1]="!isDesktop" [innerText]="menuItem.label">
                                </a>
                            </ng-template>
                            <a class="nav-link my-0 d-none d-lg-block account-pic" ngbDropdownToggle
                                *ngIf="menuItem.id && menuItem.id === 'profile' && isLoggedIn">
                                <div>
                                    <img src="./assets/img/default_profile.jpg" alt="Circle Image"
                                        class="img-circle img-responsive mb-0">
                                </div>
                            </a>
                            <div ngbDropdownMenu class="dropdown-menu-right">
                                <ng-container *ngFor="let subMenuItem of menuItem.subItems;">
                                    <a class="dropdown-item {{subMenuItem.classes}}" [class.p-1]="!isDesktop"
                                        [routerLink]="subMenuItem.route ? subMenuItem.route : []" [routerLinkActive]="subMenuItem.route ? 'active-menu-item' : ''" 
                                        [routerLinkActiveOptions]="{exact: true}" (click)="onItemClick(subMenuItem)">
                                        <i *ngIf="subMenuItem.icon" class="nc-icon {{subMenuItem.icon}}"
                                            aria-hidden="true"></i>
                                        <span [innerText]="subMenuItem.label"></span>
                                        <span *ngIf="subMenuItem?.badge && subMenuItem?.badge?.label>0 && (subMenuItem.id === 'Grievances' || subMenuItem.id === 'requests') " class="badge">{{subMenuItem?.badge?.label}}</span>
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <!-- <lib-header *ngIf="(isDesktop && hasTransparentNavbar() && !isLoggedIn)"></lib-header> -->
</nav>


<ng-template #menuToggle>
    <button class="navbar-toggler navbar-burger" type="button" data-toggle="collapse"
        data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false"
        aria-label="Toggle navigation" (click)="sidebarToggle()">
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
    </button>
</ng-template>