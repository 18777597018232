import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OTP_INTENTS, PRODUCT_CLICK_COUNT } from 'src/app/app.const';
import { QatchComponent } from 'src/app/components/qatch.component';
import { AppService } from 'src/app/shared/services/app.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LocalStorageService, STORAGE_CONSTANTS } from 'src/app/shared/services/storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PhoneUtil } from 'src/app/shared/utils/phone-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MagicLinkService } from 'src/app/shared/services/magiclink.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControlService } from 'src/app/shared/services/form-control.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent extends QatchComponent {

  authForm: FormGroup;
  loading: boolean;
  otpRequested: boolean;
  requestedToken: boolean;
  countryCode: string;
  isPhone: boolean = true;
  constructor(
    protected override authService: AuthService,
    private fb: FormBuilder,
    private appService: AppService,
    private toastService: ToastService,
    protected override deviceService: DeviceDetectorService,
    private storageService: LocalStorageService,
    private magicLinkService: MagicLinkService,
    private userService: UserService,
    protected readonly ngbModal: NgbModal,
    protected activeModal: NgbActiveModal,
    private controlService: FormControlService
  ) {
    super(deviceService, authService);
    this.countryCode = PhoneUtil.getCountryCode();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.authForm = this.fb.group({
      username: this.controlService.getPhoneControl('')
    });
  }

  async login() {
    if (this.authForm.valid) {
      this.authService.logOut();
      this.loading = true;
      this.lookupUser(async () => {
        try {
          const username = this.extractFirebaseUsername();
          await this.authService.loginWithUser(username, this.authForm.value.password.trim());
          this.storageService.storeInAppCache(STORAGE_CONSTANTS.JUST_LOGGED_IN_EVENT, 'true');
        } catch (error) {
          this.toastService.error('Please check your credentials.');
          this.loading = false;
        }
      },
        () => {
          this.loading = false;
        });
    }
  }

  extractFirebaseUsername() {
    const phoneNumber = PhoneUtil.withCountryCode(PhoneUtil.extractPhone(this.authForm.value.username));
    return PhoneUtil.emailFromPhone(phoneNumber);
  }

  async requestOTP(event: any) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.authForm.valid) {
      await this.authService.logOut();
      this.otpRequested = true;
      this.lookupUser(
        async () => {
          try {
            const didToken = await this.magicLinkService.login(this.extractData());
            this.authService.createCustomToken({ didToken }).subscribe({
              next: async (resp) => {
                this.requestedToken = true;
                await this.authService.signInWithCustomToken(resp);
                this.storageService.storeInAppCache(STORAGE_CONSTANTS.JUST_LOGGED_IN_EVENT, 'true');
                this.activeModal.dismiss();
                localStorage.removeItem(PRODUCT_CLICK_COUNT);
              },
              error: (error) => {
                this.toastService.error(error);
                this.handleFailure();
              }
            });
          } catch (error) {
            this.toastService.error(error);
            this.handleFailure();
          }
        },
        () => {
          this.otpRequested = false;
        }
      );
    }
  }

  extractData() {
    let data: { phoneNumber?: string; email?: any; };
    if (PhoneUtil.isPhone(this.authForm.value.username)) {
      data = { phoneNumber: PhoneUtil.withCountryCode(PhoneUtil.extractPhone(this.authForm.value.username)) };
    } else {
      data = { email: this.authForm.value.username };
    }
    return data;
  }

  handleFailure() {
    this.otpRequested = false;
    this.requestedToken = false;
    this.authService.logOut();
  }

  redirectToForgotPasswordPage() {
    this.storageService.storeInSession(STORAGE_CONSTANTS.OTP_INTENT, OTP_INTENTS.RESET_PASSWORD);
    this.appService.navigateWithMerge('/auth-via-phone', { queryParams: { next_page: 'reset-password', previous_page: 'signin' } });
  }

  lookupUser(successCB: () => void, errorCB: () => void) {
    const data = this.extractData();
    this.userService.lookupByUsername({username: data.email || data.phoneNumber}).subscribe({
      next: () => successCB(),
      error: (error) => { this.toastService.error(error); errorCB(); }
    })
  }

  get controls() {return this.authForm?.controls}
}
