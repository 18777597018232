import { Component, HostListener, OnInit, OnDestroy } from "@angular/core";
import { ROLES } from "src/app/app.const";
import { User } from "src/app/shared/models/user";
import { AuthService } from "src/app/shared/services/auth.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { FilterItem } from "../shared/modules/filters/filters.component";
import { cloneDeep } from "lodash";
import { UserService } from "../shared/services/user.service";
import { StylistsService } from "../pages/private/stylists/stylists.service";
import { Logger } from "../shared/utils/log-util";

@Component({
    template: ''
})
export abstract class QatchComponent implements OnInit, OnDestroy {
    filters: FilterItem[];
    isLoggedIn: boolean;
    isSubscriber: boolean;
    isAdmin: boolean;
    isStylist: boolean;
    isInfluencer: boolean;
    isDesktop = false;
    isMobile = false;
    isDestroyed = false;
    isVIPUser: boolean;
    constructor(
        protected deviceService: DeviceDetectorService,
        protected authService: AuthService,
    ) {}

    ngOnInit() {
        this.onResize();
        this.postNgOnInit();
        this.authService.user$$.subscribe(async (user) => {
            this.init(user);
        });
        this.authService.role$$.subscribe((role: string) => {
            this.updateUserTypes();
        });
    }

    init(user: User) {
        this.isLoggedIn = this.authService.isLoggedIn();
        this.postAuth(user);
        this.updateUserTypes();
    }

    updateUserTypes(){
        this.authService.me().then((user: User) => {
            this.isLoggedIn = this.authService.isLoggedIn();
            const activeRole = this.authService.getActiveRole();
            if(user && Array.isArray(user.roles)) {
                this.isSubscriber = user.roles.includes(ROLES.SUBSCRIBER);
                this.isStylist = user.roles.includes(ROLES.STYLIST) && (activeRole && activeRole === ROLES.STYLIST);
                this.isAdmin = user.roles.includes(ROLES.ADMIN) && (activeRole && activeRole === ROLES.ADMIN);
                this.isInfluencer = user.roles.includes(ROLES.INFLUENCER);
                this.isVIPUser = user.is_premium;
                this.postAuth(user);
            }
            this.postFirebaseAuth();
        }).catch((err: any) => {
            Logger.error(err);
            this.postFirebaseAuth();
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isDesktop = this.deviceService.isDesktop();
        this.isMobile = this.deviceService.isMobile();
        this.postResize();
    }

    getFiltersData(service: UserService | StylistsService, params?: any) {
        service.getFilters(this.filters.filter(i => i.filterId).map(i => i), params)
            .subscribe(({ filters }: any) => {
                this.filters = cloneDeep(this.filters.map(filter => {
                    if (filter.filterId && filters[filter.filterId]) {
                        filter.data = filters[filter.filterId];
                    }
                    return filter;
                }));
            });
      }

    postResize() {
        //console.log('Post Window Resized...');
    }

    postNgOnInit() {
        //console.log('Post Window Resized...');
    }

    postFirebaseAuth() {
        //console.log('Post Window Resized...');
    }

    postAuth(user: User){

    }
    
    isNotInViewport(elmRef) {
        if(!elmRef) {
        return false;
        }
        const rect = elmRef['el'].nativeElement.getBoundingClientRect();
        return !(
            rect.top >= 0 &&
            rect.left >= 0 &&
            ((rect.bottom - rect.height) <= (window.innerHeight || document.documentElement.clientHeight)) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    showTooltip(elem: HTMLElement, clipboardText: string, tooltipText: string) {
        elem.style.display = 'none';
        elem.setAttribute('title', 'Copied!');
        navigator.clipboard.writeText(clipboardText);
        setTimeout(() => {
            elem.style.display = 'block';
        });
        setTimeout(() => {
            elem.setAttribute('title', tooltipText);
        }, 2000);
    }

    ngOnDestroy(): void {
        this.isDestroyed = true;
    }

}