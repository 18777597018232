import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';
import { ClipboardService } from 'ngx-clipboard';
import { DeviceDetectorService } from 'ngx-device-detector';

export enum MESSAGE_TYPE {
  PRODUCT = 'product',
  OUTFIT = 'outfit',
  REFER_A_FRIEND = 'refer_a_friend'
}

interface IMsgConfig {
  title?: string,
  text?: string,
  success_message?: string
}

export interface IMsgConfigs {
  product: IMsgConfig;
  outfit: IMsgConfig;
  referafriend: IMsgConfig;
}

@Injectable({
  providedIn: 'root'
})
export class ShareWithFriendsService {
  type: MESSAGE_TYPE = MESSAGE_TYPE.PRODUCT;
  msgConfigs: IMsgConfigs = {
    product: {
      title: '`Qatch - Personalized Shopper at your fingertips`',
      text: `Check out this amazing product in my Qatch recommendations! 😍`,
      success_message: 'Product link has been copied.'
    },
    outfit: {
      title: 'My Outfit',
      text: `Hey, here is an outfit created using Qatch! Trust me, you’re gonna love it.`,
      success_message: 'Link Copied!'
    },
    referafriend: {
      title: 'Refer A Friend',
      text: `Hey, join Qatch like I did to get cute outfits via text! Trust me, you’re gonna love it.`,
      success_message: 'Referral link has been copied.'
    }
  }

  constructor(
    private toastService: ToastService,
    private deviceService: DeviceDetectorService,
    private clipboardService: ClipboardService,
  ) { }

  share(link: string, type: MESSAGE_TYPE, uTitle?: string) {
    const { title, text, success_message } = this.msgConfigs[type];
    const isMobile = this.deviceService.isMobile();
    if (isMobile && navigator.share) {
      navigator.share({
        title: uTitle || title,
        text: text,
        url: link
      }).then(() => {
        console.log('Thanks for sharing!');
      })
        .catch(console.error);
    } else {
      this.clipboardService.copy(`${type === MESSAGE_TYPE.OUTFIT ? '' : text} ${link}`);
      if (success_message) this.toastService.success(success_message);
    }
  }
}