import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encodeImageUri',
  standalone: true
})
export class EncodeImageUriPipe implements PipeTransform {

  transform(value: any, ...args: string[]): string {
    return value ? value.replace('(', '%28').replace(')', '%29') : '';
  }

}
