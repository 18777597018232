import { cloneDeep } from 'lodash';
import { IMenuItem } from '@hss-m/uikit-common';
import { ITopbarData } from '@hss-m/uikit-qp-ng';
import { ROLES } from 'src/app/app.const';

const accessCode = (arr: string[] = [ROLES.ADMIN, ROLES.STYLIST]) => {
  return arr.join(',');
}

export const MENU_DATA: IMenuItem[] = [
  {
    items: [{
      label: 'Dashboard',
      icon: 'pi pi-home',
      class: 'mt-3',
      accessCode: accessCode(),
      items: [
        { label: 'Summary', icon: 'pi pi-fw pi-home', routerLink: ['/console/summary'], accessCode: accessCode([ROLES.ADMIN]) },
        { label: 'Manage Subscribers', icon: 'pi pi-fw pi-user', routerLink: ['/console/support/subscribers'], accessCode: accessCode([ROLES.ADMIN]) },
        { label: 'My Subscribers', icon: 'pi pi-fw pi-user', routerLink: ['/console/overview'], accessCode: accessCode([ROLES.STYLIST]) },
      ]
    }]
  },
  {
    items: [{
      label: 'Queues',
      icon: 'pi pi-hourglass',
      accessCode: accessCode([ROLES.STYLIST]),
      items: [
        { label: 'All Queues', icon: 'pi pi-hourglass', routerLink: ['/console/all-queues'] },
        { label: `Today's Queues`, icon: 'pi pi-hourglass', routerLink: ['/console/all-queues/todays'] },
      ]
    }]
  },
  {
    items: [{
      label: 'Inventory',
      icon: 'pi pi-book',
      accessCode: accessCode(),
      items: [
        { label: 'Overview', icon: 'pi pi-book', routerLink: ['/console/inventory/overview'], accessCode: accessCode() },
        { label: 'Stock Check', icon: 'pi pi-user', routerLink: ['/console/inventory/stock'], accessCode: accessCode() },
        { label: 'Collections', icon: 'pi pi-user', routerLink: ['/console/inventory/collections'], accessCode: accessCode() },
      ]
    }]
  },
  {
    items: [{
      label: 'BigQuery',
      icon: 'pi pi-book',
      accessCode: accessCode(),
      items: [
        { label: 'Apex', icon: 'pi pi-book', routerLink: ['/console/apex'], accessCode: accessCode() },
        { label: 'Tagged Inventory', icon: 'pi pi-book', routerLink: ['/console/tagged-inventory'], accessCode: accessCode() }
      ]
    }]
  },
  {
    items: [
      {
        label: 'Campaigns',
        icon: 'pi pi-briefcase',
        accessCode: accessCode([ROLES.STYLIST]),
        items: [
          { label: 'Campaigns', icon: 'pi pi-building', routerLink: ['/console/campaigns'] },
          { label: 'Segments', icon: 'pi pi-shopping-cart', routerLink: ['/console/segments'] },
          { label: 'Convos', icon: 'pi pi-shopping-cart', routerLink: ['/console/convos'] },
        ]
      },
    ]
  },
  {
    items: [
      {
        label: 'Metadata',
        icon: 'pi pi-briefcase',
        accessCode: accessCode([ROLES.ADMIN]),
        items: [
          { label: 'Retailers', icon: 'pi pi-building', routerLink: ['/console/metadata/retailers'] },
          { label: 'Brands', icon: 'pi pi-shopping-cart', routerLink: ['/console/metadata/brands'] },
        ]
      },
    ]
  },
  {
    items: [
      {
        label: 'Support',
        icon: 'pi pi-question',
        accessCode: accessCode(),
        items: [
          { id: 'grievances', label: 'Grievances', icon: 'pi pi-question', 
            routerLink: ['/console/support/grievances'], 
            accessCode: accessCode([ROLES.ADMIN]) 
          },
          { id: 'requests', label: 'Requests', icon: 'pi pi-question', 
            routerLink: ['/console/support/requests'], 
            accessCode: accessCode([ROLES.STYLIST]) 
          }
        ]
      },
    ]
  }
]

export const NAVBAR_DATA = function (self: any): ITopbarData {
  return cloneDeep({
    logo: {
      label: 'Qatch'
    },
    items: [],
    profileItems: [
      {
          icon: 'pi pi-user',
          label: 'Edit Prefrences',
          routerLink: 'general/edit-account'
      },
      {
          icon: 'pi pi-arrow-right-arrow-left',
          label: 'Change Role',
          command: self.manageRolesPopup.bind(self)
      },
      {
          icon: 'nc-icon nc-support-17',
          label: 'Support',
          routerLink: 'general/support'
      },
      // {
      //     label: 'Update Password',
      //     icon: 'pi pi-key',
      //     command: () => { self.updatePassword = true; }
      // }
  ],
  });
}

export const MENU_BOTTOM_ITEMS = (self: any): IMenuItem[] => {
  return [
    {
      items: [{
        icon: "pi pi-fw pi-lock",
        label: 'Terms of Use',
        url: ['/terms'],
        target: '_blank'
      }]
    },
    {
      items: [{
        icon: "pi pi-fw pi-shield",
        label: 'Privacy Policy ',
        url: ['/privacy-policy'],
        target: '_blank'
      }]
    },
    {
      items: [{
        icon: "pi pi-fw pi-power-off",
        label: 'Logout',
        command: self.logout.bind(self)
      }]
    }
  ]
}

export function getErrorMsg(httpErrorResp: any) {
  let message = '';
  if (typeof httpErrorResp === 'string') {
    message = httpErrorResp.toString();
  } else if (httpErrorResp.rawMessage) {
    message = httpErrorResp.rawMessage;
  } else if (httpErrorResp.error) {
    if (typeof httpErrorResp.error === 'string') {
      message = httpErrorResp.error.toString();
    } else if (httpErrorResp.error.errors && Array.isArray(httpErrorResp.error.errors) && httpErrorResp.error.errors.length) {
      message = httpErrorResp.error.errors[0].msg;
    } else if (typeof httpErrorResp.error.error === 'string') {
      message = httpErrorResp.error.error;
    } else if (typeof httpErrorResp.error.error?.msg) {
      message = httpErrorResp.error.error.msg;
    }
  }

  if (!message) {
    message = 'Oops, Something unexpected has occurred. Please try again.';
  }

  return message;
}