import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToastService {
  modal = new Subject<any>();

  show(textOrTpl: string | TemplateRef<any>, success = true, autoClose: boolean=true) {
    this.modal.next({ textOrTpl, success, autoClose});
  }

  info(message: string, autoClose: boolean=true) {
    this.show(message, true, autoClose);
  }

  success(message: string, autoClose: boolean=true) {
    this.show(message, true, autoClose);
  }

  error(httpErrorResp: any, defErr?: string) {
    let message = '';
    if(typeof httpErrorResp === 'string') {
      message = httpErrorResp.toString();
    } else if (httpErrorResp.rawMessage){
      message = httpErrorResp.rawMessage;
    } else if (httpErrorResp.error){
      if(typeof httpErrorResp.error === 'string') {
        message = httpErrorResp.error.toString();
      } else if(httpErrorResp.error.errors && Array.isArray(httpErrorResp.error.errors) && httpErrorResp.error.errors.length) {
        message = httpErrorResp.error.errors[0].msg;
      } else if(typeof httpErrorResp.error.error === 'string') {
        message = httpErrorResp.error.error;
      } else if (typeof httpErrorResp.error.error?.msg) {
        message = httpErrorResp.error.error.msg;
      }
    }
 
    if(!message) {
      message = defErr || 'Oops, Something unexpected has occurred. Please try again.';
    }

    this.show(message, false, false);
  }
}
