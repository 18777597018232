<div class="wrapper page-header non-transparent-header">
    <div class="container">
        <div class="row">
            <div class="mx-auto">
                <div class="author text-center w-100 q-page-header">
                    <ng-container>
                        <h1 class="title vmb-2 q-page-header-title">Member Login</h1>
                        <strong class="underline-text-dark {{ isDesktop ? 'w-40' : 'w-100' }}">
                            Enter your mobile number below to log into your Qatch account.
                        </strong>
                    </ng-container>
                </div>
                <div class="vmy-3 q-page-container">
                    <form class="register-form mx-auto" [formGroup]="authForm" (ngSubmit)="requestOTP($event)">
                        <div id="captcha-container"></div>

                        <ng-container>
                            <label class="label vmt-3 required-field">Phone Number</label>
                            <div class="input-group form-group-no-border bg-default shadow">
                                <span class="input-suffix" *ngIf="isPhone">{{countryCode}} </span>
                                <input class="form-control box-shadow-none" formControlName="username" appPhoneMask>
                            </div>
                            <uikit-error [submitted]="false" [controls]="controls" name="username" 
                            placeholder="phone number">
                            </uikit-error>
                        </ng-container>

                        <div class="form-row justify-content-center">
                            <ng-container>
                                <button [disabled]="(!authForm.valid || loading || otpRequested)" type="submit"
                                    class="btn btn-danger vml-1 sign_in w-200px vmt-6 active">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="loading || otpRequested"></span>
                                    Sign In
                                </button>
                            </ng-container>
                            
                            <div class="w-100 vmt-6 text-center">
                                <a [routerLink]="['/signup']" class="btn btn-outline-danger btn-round sign_in_sign_up" [queryParamsHandling]="'merge'">
                                    Not a member? Sign up
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="top-left-img" *ngIf="isDesktop">
        <img src="./assets/img/organic/Shape-3.png" />
    </div>
    <div class="bottom-right-img">
        <img src="./assets/img/organic/Shape-4.png" />
    </div>
</div>