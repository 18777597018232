import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })
export class LocalStorageService {
    appCache: any = {};

    storeInAppCache(key: string, value: string) {
        this.appCache[key] =  value;
    }

    getFromAppCache(key: string, defValue?: any) {
        return this.appCache[key] || defValue;
    }

    popFromAppCache(key: string, defValue?: any) {
        const value =  this.appCache[key] || defValue;
        this.appCache[key] = null;
        return value;
    }

    removeFromAppCache(key: string) {
        this.appCache[key] = null;
    }

    storeInSession(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }

    getFromSession(key: string, defValue?: any) {
        return sessionStorage.getItem(key) || defValue;
    }

    popFromSession(key: string, defValue?: any) {
        const value =  sessionStorage.getItem(key) || defValue;
        sessionStorage.removeItem(key)
        return value;
    }

    removeFromSession(key: string) {
        return sessionStorage.removeItem(key);
    }

    hasInSession(key: string) {
        const value = sessionStorage.getItem(key);
        return value != null && value.trim().length > 0;
    }

    set(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    setObject(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    get(key: string, def: string = null) {
        return localStorage.getItem(key) || def;
    }

    pop(key: string) {
        const value = localStorage.getItem(key);
        this.remove(key);
        return value;
    }

    getObject(key: string, def?: any) {
        const val = this.get(key) || '';
        return this.has(key) ? JSON.parse(val) : (def || null);
    }

    popObject(key: string) {
        const value = this.getObject(key);
        this.remove(key);
        return value;
    }

    has(key: string) {
        const value = localStorage.getItem(key);
        return value != null && value.trim().length > 0;
    }

    hasValue(key: string, defValue: string) {
        const value = localStorage.getItem(key);
        return value && value === defValue;
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }
}

// Storage Constants
export const STORAGE_CONSTANTS = {
    QATCH_EXPERIENCE_ALERT: 'QATCH_EXPERIENCE_ALERT',
    NEXT_ROUTE: 'NEXT_ROUTE',
    PREV_ROUTE: 'PREV_ROUTE',
    PHONE_NUMBER: 'PHONE_NUMBER',
    APP_BASE_URL: 'APP_BASE_URL',
    APP_CHAT_BASE_URL: 'APP_CHAT_BASE_URL',
    APP_DASHBOARD_BASE_URL: 'APP_DASHBOARD_BASE_URL',
    APP_COLLECTION_BASE_URL: 'APP_DASHBOARD_BASE_URL',
    ALLOW_BACK_BUTTON: 'ALLOW_BACK_BUTTON',
    USER_META: 'USER_META',
    REQUEST_TOKEN_ID: 'REQUEST_TOKEN_ID',
    OTP_INTENT: 'OTP_INTENT',
    REDIRECT_TO_URL_ON_SIGNIN: 'REDIRECT_TO_URL_ON_SIGNIN',
    QATCH_COOKIE: 'QATCH_COOKIE',
    JUST_LOGGED_IN_EVENT: 'JUST_LOGGED_IN_EVENT',
    JUST_SIGNEDUP_EVENT: 'JUST_SIGNEDUP_EVENT',
    USER_TYPE: 'USER_TYPE',
    CHAT_ADVANCE_FILTERS: 'CHAT_ADVANCE_FILTERS'
}