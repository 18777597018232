import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "./app.service";
import { MESSAGE_TYPE, ShareWithFriendsService } from "./share-with-friends.service";

@Injectable({
  providedIn: 'root',
})
export class ProductService {

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private shareWithFriendsService: ShareWithFriendsService,
  ) {}

  referAProduct(product: any) {
    const link = `${product.branded_link}`;
    this.shareWithFriendsService.share(link, MESSAGE_TYPE.PRODUCT);
  }

  getProductsWithLoveCount(productIds: string[]) {
    return this.http.post(`${this.appService.getAppBaseUrl()}/inventory/loved-counts`, productIds);
  }
}
