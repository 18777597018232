import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appPhoneMask]',
  standalone: true
})
export class PhoneMaskDirective {

  constructor(
    public ngControl: NgControl,
    private el: ElementRef,
    private renderer: Renderer2
  ) { 
    this.renderer.setAttribute(this.el.nativeElement, 'inputmode', 'numeric');
    this.renderer.setAttribute(this.el.nativeElement, 'type', 'number');
    this.renderer.setAttribute(this.el.nativeElement, 'pattern', '[0-9]*');
    this.renderer.setAttribute(this.el.nativeElement, 'placeholder', 'Phone number');
  }

  @HostListener('input', ['$event'])
  keydown(event: any) {
    this.updateControlValue(event.target.value);
  }

  @HostListener('keypress', ['$event'])
  restrictNonNumeric(event: KeyboardEvent) {
      const keyVal = event.key;
      if (['-', '.'].find(k => k === keyVal) || this.ngControl.value?.length >= 10) {
        event.preventDefault();
      }
  }

  @HostListener('paste', ['$event'])
  sanitizePaste(event: ClipboardEvent) {
    event.preventDefault();
    this.updateControlValue(event.clipboardData?.getData('text'));
  }

  updateControlValue(value) {
    const sanitizedData = (value || '').replace(/[^0-9]/g, '').slice(0, 10);
    this.ngControl.control.setValue(sanitizedData, { emitEvent: false });
    this.ngControl.control.markAsTouched();
    this.ngControl.control.updateValueAndValidity();
  }

}
